var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from "react";
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import { ThemeContext, ThemeProvider } from "./Theme";
import { HomeScreen, WorkScreen, AppsScreen, NonAppsScreen, KKGMScreen } from "./Screens";
// @ts-ignore-next-line
import LinearGradient from "react-native-web-linear-gradient";
import { ScreenHeader } from "./Components";
var Stack = createStackNavigator();
// the base app component wraps the app in our theme provider and fully expands to screen size
var App = function () {
    return (_jsx(ThemeProvider, { children: _jsx(Navigator, {}) }));
};
// Here is where all of our screens and paths are defined in a central naviator
// We also define our background color here
var Navigator = function () {
    var theme = useContext(ThemeContext);
    // we pass an empty theme to the navigation container because we prefer to use our theme context
    var emptyTheme = {
        dark: false,
        colors: {
            background: "transparent",
            primary: "",
            card: "",
            text: "",
            border: "",
            notification: "",
        },
    };
    // setup our header on all screens
    var defaultOptions = {
        header: ScreenHeader,
    };
    // setup linking to allow the url path to be used to navigate, as well as the back button
    var config = {
        screens: {
            Home: "home",
            Apps: "apps",
            NonApps: "nonapps",
            Work: "work",
            KKGM: "kkgm",
            LoreCraft: "lorecraft"
        },
    };
    var linking = { config: config, prefixes: [] };
    return (_jsx(LinearGradient, __assign({ colors: theme.background, style: { height: "100vh", overflow: "hidden" } }, theme.linearGradient, { children: _jsx(NavigationContainer, __assign({ theme: emptyTheme, linking: linking }, { children: _jsxs(Stack.Navigator, __assign({ initialRouteName: "Home" }, { children: [_jsx(Stack.Screen, { name: "Home", component: HomeScreen, options: defaultOptions }), _jsx(Stack.Screen, { name: "Apps", component: AppsScreen, options: defaultOptions }), _jsx(Stack.Screen, { name: "NonApps", component: NonAppsScreen, options: defaultOptions }), _jsx(Stack.Screen, { name: "Work", component: WorkScreen, options: defaultOptions }), _jsx(Stack.Screen, { name: "KKGM", component: KKGMScreen, options: defaultOptions }), _jsx(Stack.Screen, { name: "LoreCraft", component: KKGMScreen, options: defaultOptions })] })) })) })));
};
export default App;
