var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { View } from "react-native";
import { useContext } from "react";
import { Flex, Padding, StyledText } from "../Components";
import { ShowcaseButton } from "./ShowcaseButton";
import { ThemeContext } from "../Theme";
import { FadeInImage } from "./FadeInImage";
import { isScreenSmall } from "../Helpers";
// This component shows off a project/app/job/etc
// It displays a title, description, image, and links
export var ShowcaseRow = function (_a) {
    var title = _a.title, description = _a.description, tech = _a.tech, image = _a.image, _b = _a.horizontalImage, horizontalImage = _b === void 0 ? false : _b, _c = _a.sharpEdges, sharpEdges = _c === void 0 ? false : _c, android = _a.android, apple = _a.apple, link = _a.link, customImageDimensions = _a.customImageDimensions, _d = _a.isFirst, isFirst = _d === void 0 ? false : _d;
    var theme = useContext(ThemeContext);
    var imageWidth = customImageDimensions
        ? customImageDimensions.width
        : horizontalImage
            ? theme.showcaseImageLong
            : theme.showcaseImageShort;
    var imageHeight = customImageDimensions
        ? customImageDimensions.height
        : horizontalImage
            ? theme.showcaseImageShort
            : theme.showcaseImageLong;
    // we dont need a listener since the theme listens for us
    var singleColumn = isScreenSmall();
    var buttons = (_jsxs(Flex, __assign({ row: true, style: { marginTop: "auto" } }, { children: [apple && (_jsx(ShowcaseButton, { link: apple, name: "Apple", singleColumn: singleColumn })), android && (_jsx(ShowcaseButton, { link: android, name: "Android", singleColumn: singleColumn })), link && (_jsx(ShowcaseButton, { link: link, name: "Link", singleColumn: singleColumn }))] })));
    var titleComponent = (_jsx(StyledText, __assign({ type: "body", style: {
            width: singleColumn ? undefined : theme.showcaseTextWidth,
            textAlign: singleColumn ? "center" : undefined,
            paddingRight: singleColumn ? theme.smallSpace : undefined,
        } }, { children: title })));
    var descriptionComponent = (_jsxs(_Fragment, { children: [_jsx(StyledText, __assign({ type: "caption", style: {
                    width: singleColumn ? undefined : theme.showcaseTextWidth,
                    textAlign: singleColumn ? "center" : undefined,
                } }, { children: description })), tech && _jsx(StyledText, __assign({ type: "subscript", style: {
                    width: singleColumn ? undefined : theme.showcaseTextWidth,
                    textAlign: singleColumn ? "center" : undefined,
                } }, { children: tech }))] }));
    var imageComponent = (_jsx(FadeInImage, { spinner: true, source: image, style: {
            width: imageWidth,
            height: imageHeight,
            resizeMode: "stretch",
            borderRadius: sharpEdges ? 5 : 20,
            position: singleColumn ? undefined : "absolute",
            left: singleColumn ? undefined :
                (customImageDimensions === null || customImageDimensions === void 0 ? void 0 : customImageDimensions.left) ? customImageDimensions.left :
                    imageHeight / 2,
        } }));
    // on larger screens, we have 2 columns. in the left is the title, description, and buttons. in the right is the image
    // on smaller screens, we have 1 column. This is then split based on the orientation of the image:
    // if the image is vertical, we have the title and buttons to the left of the image, then the description below both of them
    // if the image is horizontal, we have them all stacked: title above image above description above buttons
    return (_jsx(Flex, __assign({ fullWidth: true, centered: true }, { children: _jsxs(Flex, __assign({ slim: true, centered: true }, { children: [(!isFirst || !singleColumn) && _jsx(View, { style: theme.showcaseDivider }), _jsx(Padding, { vertical: theme.largeSpace }), singleColumn ? (_jsx(Flex, __assign({ centered: true }, { children: horizontalImage || customImageDimensions ? (_jsxs(_Fragment, { children: [titleComponent, _jsx(Padding, { vertical: theme.mediumSpace }), imageComponent, _jsx(Padding, { vertical: theme.mediumSpace }), descriptionComponent, _jsx(Padding, { vertical: theme.smallSpace }), buttons] })) : (_jsxs(_Fragment, { children: [_jsxs(Flex, __assign({ row: true, centered: true }, { children: [_jsxs(Flex, __assign({ centered: true, full: true }, { children: [titleComponent, _jsx(Padding, { vertical: theme.smallSpace }), buttons] })), _jsx(Padding, { horizontal: theme.smallSpace }), _jsx(Flex, __assign({ full: true }, { children: imageComponent }))] })), _jsx(Padding, { vertical: theme.mediumSpace }), descriptionComponent] })) }))) : (_jsxs(Flex, __assign({ row: true }, { children: [_jsxs(Flex, { children: [titleComponent, _jsx(Padding, { vertical: theme.mediumSmallSpace }), descriptionComponent, _jsx(Padding, { vertical: theme.smallSpace }), buttons] }), _jsx(View, __assign({ style: { width: theme.showcaseImageLong, height: imageHeight } }, { children: imageComponent }))] }))), _jsx(Padding, { vertical: theme.largeSpace })] })) })));
};
